import React from "react"
import Layout from "../../components/layout/layout"
import SEOComponent from "../../components/seo"
import LoginForm from "../../components/login/Login";
import LoginFooter from "../../components/login/LoginFooter";

const Login = () => (
    <Layout mode={true} no_header_margin extra_class={'login'} customFooter={true} FooterComp={LoginFooter}>
        <SEOComponent title="Amber by inFeedo | Login" />
        <LoginForm route={'dashboard'} />
    </Layout>
)

export default Login